type StorageType = 'localStorage' | 'sessionStorage'

export enum StorageKeys {
  LeadFormSubmitted = 'leadFormSubmitted',
}

export class Store {
  private static instances: Record<StorageType, Store> = {} as Record<
    StorageType,
    Store
  >
  private storage: Storage | null

  private constructor(storageType: StorageType) {
    if (typeof window === 'undefined') {
      // Fallback to a no-op storage implementation on the server
      this.storage = null
    } else {
      const StorageObject = {
        localStorage: window.localStorage,
        sessionStorage: window.sessionStorage,
      }

      this.storage =
        storageType in StorageObject
          ? StorageObject[storageType]
          : window.localStorage
    }
  }

  // Static method to get a singleton instance
  public static getInstance(storageType: StorageType): Store {
    if (!Store.instances[storageType]) {
      Store.instances[storageType] = new Store(storageType)
    }
    return Store.instances[storageType]
  }

  public setItem(key: string, value: any) {
    if (!this.storage) {
      console.warn(
        `Storage is not available on the server. Ignoring setItem for key "${key}".`
      )
      return
    }
    try {
      const valueStr = JSON.stringify(value)
      this.storage.setItem(key, valueStr)
    } catch (error) {
      console.error(`Error setting item "${key}" in storage:`, error)
    }
  }

  public getItem<T>(key: string): T | null {
    if (!this.storage) {
      console.warn(
        `Storage is not available on the server. Returning null for key "${key}".`
      )
      return null
    }
    const value = this.storage.getItem(key)
    if (!value) return null
    try {
      return JSON.parse(value)
    } catch (error) {
      console.error(`Error parsing value for key "${key}" from storage:`, error)
      return null
    }
  }

  public removeItem(key: string) {
    if (!this.storage) {
      console.warn(
        `Storage is not available on the server. Ignoring removeItem for key "${key}".`
      )
      return
    }
    this.storage.removeItem(key)
  }

  public clear() {
    if (!this.storage) {
      console.warn(
        'Storage is not available on the server. Ignoring clear operation.'
      )
      return
    }
    this.storage.clear()
  }
}
