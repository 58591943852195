import { useEffect, useMemo, useState } from 'react'

import { OptimizelyDecision } from '@optimizely/react-sdk'
import { browserLogger } from 'lib/Logger/browserLogs'

import { optimizelyInstance } from './optimizelyInstance'
import {
  cacheDecision,
  getCachedDecision,
} from './utils/getCachedDecisionResponse'

const DEFAULT_VALUES = {
  variables: {},
  variationKey: 'off',
  enabled: false,
  flagKey: '',
  reasons: [],
  ruleKey: '',
  userContext: { id: '' },
}

interface Feature {
  name: string
  fallbackVariation: string
  bypassActivate?: boolean
}

export const useDecisionForFlag = ({
  name,
  fallbackVariation,
  bypassActivate = false,
}: Feature) => {
  const [isLoading, setLoading] = useState(true)
  const [decision, setDecision] = useState<OptimizelyDecision | null>(null)
  const instance = useMemo(() => optimizelyInstance(), [])

  const handleDecision = (decision: OptimizelyDecision) => {
    setDecision(decision)
    setLoading(false)
  }

  const handleInstanceDecision = async () => {
    if (!instance) return
    try {
      await instance.onReady()
      const decision = instance.decide(name)
      handleDecision(decision)
      cacheDecision(name, decision)
    } catch (error) {
      browserLogger.error('Error getting decision for flag', {
        error,
        flagKey: name,
      })
      handleDecision(DEFAULT_VALUES)
    }
  }

  useEffect(() => {
    if (bypassActivate) {
      handleDecision({
        ...DEFAULT_VALUES,
        variationKey: fallbackVariation,
        enabled: true,
        flagKey: name,
      })
      return
    }

    const cachedDecision = getCachedDecision(name)
    if (cachedDecision) {
      handleDecision(cachedDecision)
      return
    }

    handleInstanceDecision()
  }, [bypassActivate, fallbackVariation, name])

  const decisionState = useMemo(
    () => ({
      isEnabled: decision?.enabled,
      variation: decision?.variationKey || fallbackVariation,
      variables: decision?.variables,
      isLoading,
    }),
    [decision, fallbackVariation, isLoading]
  )

  return decisionState
}
