import { Store } from '@/services/storage'
import { OptimizelyDecision } from '@optimizely/react-sdk'

interface DecisionResponse extends OptimizelyDecision {
  dateAdded: Date
  url: string
}

const MAX_CACHE_DATE = 15 * 60 * 1000 // 15 minutes

export const getCachedDecision = (featureKey: string) => {
  const store = Store.getInstance('sessionStorage')
  const decision = store.getItem<DecisionResponse>(featureKey)
  if (!decision) return null

  const currentPath = window.location.pathname
  const now = new Date()
  const cacheDate = new Date(decision.dateAdded)
  const shouldClearCache =
    now.getTime() - cacheDate.getTime() > MAX_CACHE_DATE ||
    decision.url !== currentPath

  if (shouldClearCache) {
    store.removeItem(featureKey)
    return null
  }
  return decision
}

export const cacheDecision = (
  featureKey: string,
  decision: OptimizelyDecision
) => {
  const store = Store.getInstance('sessionStorage')
  const cachedDecision = getCachedDecision(featureKey)
  if (cachedDecision) return

  const decisionToSave: DecisionResponse = {
    ...decision,
    dateAdded: new Date(),
    url: window.location.pathname,
  }
  store.setItem(featureKey, decisionToSave)

  return decision
}
